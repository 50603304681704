import React from "react";
import PropTypes from "prop-types";
import logErrorToEndpoint from "./aws-cloudwatch-logger";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  async componentDidCatch(error, info) {
    console.error("Component error:", error, info);
    await logErrorToEndpoint({ error, info }, "component-error");
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
  };

export default ErrorBoundary;
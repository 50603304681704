import React, { useState, useContext, useEffect } from "react";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import BlankTableComponent from "./subcomponents/BlankTableComponent";
import { appContext } from "../AppProvider";
import { CSVLink } from "react-csv";
import { ClipLoader } from "react-spinners";
import { useQuery } from "@apollo/client";
import {
    GET_NO_STATUS_REPORT,
    GET_TOTAL_RECORDS_REPORT,
} from "../graphql/queries";
import { columns } from "./subcomponents/GroupedRecordsTableColumns";
import Pagination from "./subcomponents/Pagination";
import PropTypes from "prop-types";

const GroupedRecordsTable = ({ agentFilterChanged, setAgentFilterChanged }) => {
    const {
        groupedRecordsData,
        groupedRecordsLoading,
        groupedRecordCountLoading,
        totalGroupedCount,
        setGroupedRecordOffset,
    } = useContext(appContext);

    const {
        loading: groupedNoStatusRecordsLoading,
        data: groupedNoStatusRecordsData,
    } = useQuery(GET_NO_STATUS_REPORT);

    const {
        loading: groupedTotalRecordsLoading,
        data: groupedTotalRecordsData,
    } = useQuery(GET_TOTAL_RECORDS_REPORT);

    const [data, setData] = useState([]);
    const [exportData, setExportData] = useState([]);
    const [exportTotalData, setExportTotalData] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = totalGroupedCount
        ? Math.ceil(totalGroupedCount / 30)
        : 1;

    useEffect(() => {
        if (groupedRecordsLoading && !groupedRecordsData) {
            setData([]);
        } else {
            setData(groupedRecordsData);
        }
    }, [groupedRecordsData, groupedRecordsLoading]);

    useEffect(() => {
        if (!groupedNoStatusRecordsLoading && groupedNoStatusRecordsData) {
            const filteredData =
                groupedNoStatusRecordsData.read_only_agent_auditor_listings_transactions_view;

            const groupedData = filteredData.reduce((acc, record) => {
                const key = `${record.agent_name}-${record.agent_team}-${record.mls_code}`;
                if (!acc[key]) {
                    acc[key] = {
                        "MLS Code": record.mls_code,
                        "Agent Name": record.agent_name,
                        "Agent Team": record.agent_team,
                        "Agent State": record.agent_state,
                        "MLS Assignment Id": record.agent_details?.mls_assignment_id,
                        "MLS Name": record.agent_details?.mls_name,
                        "MLS Id": record.agent_details?.mls_id,
                        "Number of Records": 0,
                    };
                }
                acc[key]["Number of Records"] += 1;
                return acc;
            }, {});

            const formattedData = Object.values(groupedData);

            setExportData(formattedData);
        }
    }, [groupedNoStatusRecordsData, groupedNoStatusRecordsLoading]);

    useEffect(() => {
        if (!groupedTotalRecordsLoading && groupedTotalRecordsData) {
            const filteredData = groupedTotalRecordsData.read_only_all_agents;

            const groupedData = filteredData.reduce((acc, record) => {
                const key = `${record.agent_name}-${record.mls_code}`;
                if (!acc[key]) {
                    acc[key] = {
                        "Agent MLS": record.mls_code,
                        "Agent Name": record.agent_name,
                        "Agent Team": record.agent_warehouse_generated_team,
                        "Number of Records": 0,
                    };
                }
                acc[key]["Number of Records"] +=
                    record?.transactions_aggregate?.aggregate?.count || 0;
                return acc;
            }, {});

            const formattedData = Object.values(groupedData);

            setExportTotalData(formattedData);
        }
    }, [groupedTotalRecordsData, groupedTotalRecordsLoading]);

    const table = useReactTable({
        data: data || [],
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    useEffect(() => {
        resetPaging();
    }, [agentFilterChanged]);

    const resetPaging = () => {
        if (agentFilterChanged) {
            setCurrentPage(1);
            setAgentFilterChanged(false);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setGroupedRecordOffset((page - 1) * 30);
    };

    const getCurrentDate = () => {
        const date = new Date();
        return date.toISOString().split("T")[0];
    };

    return !groupedRecordsLoading ? (
        <div>
            <div className="table-title-component">
                <h4 className="table-title-header">Grouped by Agent</h4>

                <div></div>
                <div>
                    {!groupedNoStatusRecordsLoading &&
                    groupedNoStatusRecordsData ? (
                        <CSVLink
                            data={exportTotalData}
                            id="export-total-data"
                            filename={`Total_Records_By_Agents_${getCurrentDate()}.csv`}
                        >
                            Export Total Records Report
                        </CSVLink>
                    ) : (
                        <div>
                            Loading...{" "}
                            <ClipLoader
                                size={24}
                                color={"#1fa02f"}
                                loading={true}
                            />
                        </div>
                    )}
                </div>
                <div>
                    {!groupedNoStatusRecordsLoading &&
                    groupedNoStatusRecordsData ? (
                        <CSVLink
                            data={exportData}
                            id="export-data"
                            filename={`No_Status_Records_By_Agents_${getCurrentDate()}.csv`}
                        >
                            Export No Status Report
                        </CSVLink>
                    ) : (
                        <div>
                            Loading...{" "}
                            <ClipLoader
                                size={24}
                                color={"#1fa02f"}
                                loading={true}
                            />
                        </div>
                    )}
                </div>
            </div>
            <BlankTableComponent table={table} />
            <div
                style={{
                    textAlign: "end",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </div>
                <div>
                    <span>
                        Page {currentPage} of{" "}
                        {isNaN(totalPages) ? 1 : totalPages}
                    </span>
                    <span style={{ display: "block", color: "grey" }}>
                        Showing{" "}
                        <span
                            style={{ display: "contents", fontWeight: "bold" }}
                        >
                            {groupedRecordsData?.length}
                        </span>{" "}
                        Records
                    </span>
                    <span style={{ display: "contents", fontWeight: "bold" }}>
                        Total Records:
                    </span>{" "}
                    {!groupedRecordCountLoading ? totalGroupedCount : 0}
                </div>
            </div>
        </div>
    ) : (
        <span> Loading... </span>
    );
};

GroupedRecordsTable.propTypes = {
    agentFilterChanged: PropTypes.bool,
    setAgentFilterChanged: PropTypes.func,
};

export default GroupedRecordsTable;

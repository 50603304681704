import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import { groupBadgeStyles, groupStyles, selectStyles } from "../styles";
import { formatAgentsAndTeamsForDropdown, clearFilter } from "../utils";
import { appContext } from "../AppProvider";

const AgentFilter = ({ onFilterChange, agentValue, teamValue }) => {
    const [clearInput, setClearInput] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null); 

    const navigate = useNavigate();

    const {
        agentData,
        agentsLoading: loading,
        setAgentFilter,
        setTeamFilter,
        setGroupedRecordOffset,
        setRecordOffset
    } = useContext(appContext);

    const [agentList, setAgentList] = useState(clearFilter);

    const handleAgentOrTeamSelection = (target) => {
        setGroupedRecordOffset(0);
        setRecordOffset(0);
        if (target.value === false) {
            setAgentFilter(false);
            setTeamFilter(false);
            onFilterChange({ agentFilter: false, teamFilter: false });
            setClearInput(true);
            setSelectedOption(null); // Reset selected option
            const currentParams = new URLSearchParams(location.search);
            currentParams.delete('agentFilter');
            currentParams.delete('teamFilter');
            navigate(location.pathname + '?' + currentParams.toString()); // Navigate to the new URL
            return;
        }

        const { filter, category } = target;

        if (category === 'teams') {
            setClearInput(false);
            setAgentFilter(false);
            setTeamFilter(filter);
            onFilterChange({ teamFilter: filter, agentFilter: false });
            setSelectedOption(target); 
        }

        if (category === 'agents') {
            setClearInput(false);
            setTeamFilter(false);
            setAgentFilter(filter);
            onFilterChange({ agentFilter: filter, teamFilter: false });
            setSelectedOption(target);
        }
    }

    useEffect(() => {
        const agents = formatAgentsAndTeamsForDropdown(agentData);
        setAgentList(clearFilter.concat(agents));
    }, [agentData]);

    const formatGroupLabel = (agentsAndTeamsData) => (
        <div style={groupStyles}>
            <span>{agentsAndTeamsData.label}</span>
            <span style={groupBadgeStyles}>{agentsAndTeamsData?.options?.length}</span>
        </div>
    );

    const findOption = (list, value) => {
        const found = list?.options.find(option => option.filter === value);
        return found;
    };

    useEffect(() => {
        if (!loading) {
            const selected = findOption(agentValue != null ? agentList[1] : agentList[2], agentValue || teamValue);
            setSelectedOption(selected);
        }
    }, [agentValue, teamValue, agentList, loading]);

    return (
        <Select
            options={loading ? clearFilter : agentList}
            placeholder={loading ? "Loading Teams and Agents..." : "Select Team or Agent"}
            onChange={handleAgentOrTeamSelection}
            styles={selectStyles}
            value={clearInput ? '' : selectedOption}
            formatGroupLabel={formatGroupLabel}
        />
    );
}

AgentFilter.propTypes = {
    onFilterChange: PropTypes.func.isRequired,
    agentValue: PropTypes.string,
    teamValue: PropTypes.string,
};

export default AgentFilter;
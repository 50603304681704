import React, { useContext, useEffect, useState } from "react";
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import BlankTableComponent from "./subcomponents/BlankTableComponent";
import { appContext } from "../AppProvider";
import { columns } from "./subcomponents/IndividualRecordsTableColumns";
import { CSVLink } from "react-csv";
import Pagination from "./subcomponents/Pagination";
import { useLazyQuery } from '@apollo/client';
import { GET_INDIVIDUAL_LISTINGS_DATA_EXPORT } from "../graphql/queries";
import { IoReload } from "react-icons/io5";
import PropTypes from "prop-types";

const customOrderList = [
  "agent_name",
  "agent_huddle_email",
  "agent_team",
  "transaction_buyer",
  "transaction_buyer_status",
  "transaction_seller",
  "transaction_seller_status",
  "mls_agent_transaction_side",
  "listing_close_date",
  "parcel_transfer_date",
  "listing_address",
  "address_match",
  "parcel_address",
  "listing_zip",
  "parcel_zip",
  "listing_city",
  "parcel_city",
  "listing_state",
  "parcel_state",
  "listing_year_built",
  "listing_close_price",
  "parcel_transfer_value",
  "buyer_agent_commission",
  "listing_agent_commission",
  "parcel_transfer_type",
  "listing_agent_name",
  "buyer_agent_name",
  "listing_broker_office_name",
  "buyer_broker_office_name",
  "mls_code",
  "mls_closed_listing_number",
  "parcel_transfer_data_provider",
  "parcel_transfer_recency",
  "date_delta_listing_closed_parcel_transfer",
  "date_delta_listing_closed_parcel_filing",
  "agent_source",
  "agent_state",
  "agent_huddle3_contact_id",
  "zips_match",
  "cities_match",
  "states_match",
  "match_quality",
  "flag_status",
  "notes",
  "manager_status",
  "final_disposition",
  "person_account_first_name",
  "person_account_last_name",
  "opportunity_first_last",
  "initial_area_of_interest",
  "created_date",
  "year_of_inquiry",
  "contact_established_date",
  "agent_first_interaction_date",
  "stage",
  "type",
  "phone",
  "person_account_email",
  "opportunity_owner",
  "program",
  "contract_status",
  "status",
  "archive_reason",
  "source",
];

const IndividualRecordsTable = ({agentFilterChanged, setAgentFilterChanged}) => {
  const {
    recordData,
    recordsLoading,
    setRecordOffset,
    refetch,
    individualRecordsCountLoading,
    individualRecordsCount,
    sortColumn,
    sortDirection,
    queryFilters,
  } = useContext(appContext);

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = individualRecordsCount ? Math.ceil(individualRecordsCount / 30) : 1;

  const [data, setData] = useState([]);

  useEffect(() => {
    if (recordsLoading) {
      setData([]);
    } else {
      setData(recordData);
    }
  }, [recordData, recordsLoading]);


  const table = useReactTable({
    data: data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    onSortingChange: refetch,
    getSortedRowModel: getSortedRowModel(),
  });

  useEffect(() => {
    resetPaging();
  }, [agentFilterChanged]);

  const resetPaging = () => {
    if (agentFilterChanged) {
      setCurrentPage(1);
      setAgentFilterChanged(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setRecordOffset((page - 1) * 30);
  };

  const flattenObject = (obj) => {
    const flattened = {};

    Object.keys(obj).forEach((key) => {
      const value = obj[key];

      if (
        typeof value === "object" &&
        value !== null &&
        !Array.isArray(value)
      ) {
        Object.assign(flattened, flattenObject(value));
      } else {
        flattened[key] = value;
      }
    });

    return flattened;
  };

  const [newData, setNewData] = useState([]);

  
  const UseGetAllIndividualListingData = () => {
    const column = sortColumn;
    const direction = sortDirection;
    const filters = queryFilters;
  
    const GET_INDIVIDUAL_LISTINGS_DATA = GET_INDIVIDUAL_LISTINGS_DATA_EXPORT(column, direction, filters);
  
    const [getIndividualListingsData, { loading, error, data, called }] = useLazyQuery(GET_INDIVIDUAL_LISTINGS_DATA, {
      fetchPolicy: "network-only"
    });
  
    let exportedData = data?.read_only_agent_auditor_listings_transactions_view;
  
    return {
      getIndividualListingsData,
      loading,
      error,
      data: exportedData,
      called
    };
  };

const {
  getIndividualListingsData,
  loading,
  data: fetchedData,
  called,
} = UseGetAllIndividualListingData();

useEffect(() => {
    if (fetchedData && fetchedData.length > 0) {
      setNewData(fetchedData);
    }
}, [fetchedData]);

  const getIndividualListingData = () => {

    const removedFields = [
      "__typename",
      "sales",
      "leads",
      "auditInfo",
      "listing_agent_mls_id",
      "mls_closed_listing_id",
      "auditor_status",
      "listing_after_agent_hire_date"
    ];
    const emptyData = customOrderList.reduce((acc, key) => {
      if (!removedFields.includes(key)) {
        acc[key] = "";
      }
      return acc;
    }, {});

    const flattenedData = newData?.map((record) => flattenObject(record));

    const acceptableStatuses = ["Escalate", "Definitive", "Possible", "Likely"];

    const sortedDataArray = flattenedData
      .filter((dataEntry) => acceptableStatuses.includes(dataEntry.manager_status))
      .map((dataEntry) => {
        const sortedData = { ...emptyData };
        Object.entries(dataEntry)
          .filter(([key]) => !removedFields.includes(key))
          .forEach(([key, value]) => {
            if (key === "buyery_agent_office_name") {
              sortedData["buyer_broker_office_name"] = value;
            } else {
              sortedData[key] = value;
            }
          });

        return sortedData;
      });

    return sortedDataArray;
  };

  const [exportData, setExportData] = useState([]);
  
  const [exportClicked, setExportClicked] = useState(false);


// useEffect to handle setting newData after fetching
useEffect(() => {
  if (!loading && called && fetchedData) {
    setNewData(fetchedData); // Ensures newData is updated with the fetched data
    setButtonClicked(true);
  }
}, [loading, called, fetchedData]);

const [buttonClicked, setButtonClicked] = useState(false);

const handleExportClick = () => {
  getIndividualListingsData(); // Executes the query
  setExportClicked(true); 
};

// Processing and setting export data after newData is updated
useEffect(() => {
  if (newData && newData.length > 0) {
    const sortedDataArray = getIndividualListingData(newData); // Processes newData
    setExportData(sortedDataArray); // Sets export data for use elsewhere
  }
}, [newData]); // Watches for changes in newData

// useEffect to handle data export when both exportClicked and exportData are ready
useEffect(() => {
  if (exportClicked && exportData) {
    setExportClicked(false); // Resets exportClicked to false
  }
}, [exportClicked, exportData]); // Watches for changes in exportClicked and exportData

  return !recordsLoading ? (
    <div>
      <div className="table-title-component">
        <h4 className="table-title-header">Individual Listings</h4>
        <div>
          <button id="reload-mail-merge" onClick={handleExportClick}><IoReload /></button>
          {buttonClicked ? (
            <CSVLink
              data={exportData}
              onClick={() => setButtonClicked(false)}
              id="export-data"
              filename="Agent Auditing Data.csv"
            >
              Mail Merge
            </CSVLink>
          ) : (
            <button disabled>Mail Merge</button>
          )}
        </div>
      </div>
      <BlankTableComponent table={table} />
      <div
        style={{ textAlign: "end", display: "flex", flexDirection: "column" }}
      >
        <div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
        <div style={{ textAlign: "end" }}>
          <span>
            Page {currentPage} of {isNaN(totalPages) ? 1 : totalPages}
          </span>
          <span style={{ display: "block", color: "grey" }}>
            Showing{" "}
            <span style={{ display: "contents", fontWeight: "bold" }}>
              {recordData?.length}
            </span>{" "}
            Records
          </span>
          <span style={{ display: "contents", fontWeight: "bold" }}>
            Total Records:
          </span>{" "}
          {!individualRecordsCountLoading ? individualRecordsCount : 0}
        </div>
      </div>
    </div>
  ) : (
    <span>Loading...</span>
  );
};


IndividualRecordsTable.propTypes = {
  agentFilterChanged: PropTypes.bool,
  setAgentFilterChanged: PropTypes.func,
};

export default IndividualRecordsTable;

import React, { useEffect } from "react";
import { ApolloClient, ApolloProvider } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { split, HttpLink } from "@apollo/client";
import { Route, Routes } from "react-router-dom";
import { AppProvider } from "./AppProvider";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { cache } from "./cache";
import { createClient } from "graphql-ws";
import RecordFinderContainer from "./containers/RecordFinderContainer";
import AuditRecordPage from "./containers/AuditRecordPage";
import { Security, LoginCallback } from "@okta/okta-react";
import { OKTA_CONFIG } from "./okta-config";
import { useNavigate } from "react-router-dom";
import SecureRoute from './SecureRoute';
import ErrorBoundary from "./ErrorBoundary";
import logErrorToCloudWatch from "./aws-cloudwatch-logger";

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GQL_HOST_HTTP,
  headers: {
    "x-hasura-admin-secret": process.env.REACT_APP_GQL_AUTH,
  },
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_GQL_HOST_WS,
    connectionParams: async () => {
      return {
        headers: {
          "x-hasura-admin-secret": process.env.REACT_APP_GQL_AUTH,
        },
      };
    },
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  link: splitLink,
  cache: cache,
});

const App = () => {
  const navigate = useNavigate(); 

  const toRelativeUrl = (absoluteUrl, baseUrl) => {
    return absoluteUrl.replace(baseUrl, "");
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  useEffect(() => {
    const handleGlobalError = (event) => {
      console.error("Global error:", event.error);
      logErrorToCloudWatch(event.error, "global-error");
    };

    const handleUnhandledRejection = (event) => {
      console.error("Unhandled Promise rejection:", event.reason);
      logErrorToCloudWatch(event.reason, "unhandled-rejection");
    };

    window.addEventListener("error", handleGlobalError);
    window.addEventListener("unhandledrejection", handleUnhandledRejection);

    return () => {
      window.removeEventListener("error", handleGlobalError);
      window.removeEventListener("unhandledrejection", handleUnhandledRejection);
    };
  }, []);

  return (
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <Security oktaAuth={OKTA_CONFIG} restoreOriginalUri={restoreOriginalUri}>
          <div>
            <AppProvider>
              <h2 id="app-name">Agent Auditing Tool</h2>
              <Routes>
                <Route path="/login/callback" element={<LoginCallback />} />
                <Route index element={
                  <SecureRoute>
                    <RecordFinderContainer />
                  </SecureRoute>
                  } />
                <Route
                  path="/listing/:listing_id/agent/:agent_huddle3_contact_id/transaction/:parcel_transaction_unique_id/surrogate/:parcel_surrogate_unique_id"
                  element={
                    <SecureRoute>
                      <AuditRecordPage />
                    </SecureRoute>
                  }
                />
              </Routes>
            </AppProvider>
          </div>
        </Security>
      </ApolloProvider>
    </ErrorBoundary>
  );
};

export default App;
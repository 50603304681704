import React, { useContext, useState } from "react";
import GroupedRecordsTable from "../components/GroupedRecordsTable";
import IndividualRecordsTable from "../components/IndividualRecordsTable";
import RecordFilterContainer from "./RecordFilterContainer";
import { appContext } from "../AppProvider";
import RecordSearchBar from "./RecordSearchBar";

const RecordFinderContainer = () => {
    const { groupByAgent, setGroupByAgent, refetch } = useContext(appContext);
    const [agentFilterChanged, setAgentFilterChanged] = useState(false);

    const toggleGrouping = () => {
        setGroupByAgent(!groupByAgent);
    };

    const setAgentFilter = () => {
        setAgentFilterChanged(true);
    }

    return (
        <div className="record-view-container">
            <RecordSearchBar refetch={refetch}></RecordSearchBar>
            <RecordFilterContainer
                groupByAgent={groupByAgent}
                toggleGrouping={toggleGrouping}
                setAgentFilterChanged={setAgentFilter}
            />
            <div id="record-table-container">
                {groupByAgent ? <GroupedRecordsTable setAgentFilterChanged={setAgentFilterChanged} agentFilterChanged={agentFilterChanged}/> : <IndividualRecordsTable setAgentFilterChanged={setAgentFilterChanged} agentFilterChanged={agentFilterChanged} />}
            </div>
        </div>
    );
};

export default RecordFinderContainer;
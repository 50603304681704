import { createRoot } from 'react-dom/client';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import './index.css';
import logErrorToCloudWatch from './aws-cloudwatch-logger'; 

window.addEventListener('error', (event) => {
  console.error('Global error:', event.error);
  logErrorToCloudWatch(event.error, 'global-error');
});

window.addEventListener('unhandledrejection', (event) => {
  console.error('Unhandled Promise rejection:', event.reason);
  logErrorToCloudWatch(event.reason, 'unhandled-rejection');
});

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);